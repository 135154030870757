export interface DateGroup {
	name: string;
	title: string;
}

export const DATE_GROUPS: DateGroup[] = [
	{
		name: 'day',
		title: 'День',
	},
	{
		name: 'week',
		title: 'Неделя',
	},
	{
		name: 'month',
		title: 'Месяц',
	},
];
