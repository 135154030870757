import { ReportMenuItem } from './report-menu-item.type';
import { ReportModuleCode } from './module-code';

export const REPORTS_MENU: ReportMenuItem[] = [
	{
		title: 'Основные показатели',
		code: ReportModuleCode.General,
		children: [],
	},
	{
		title: 'Реклама',
		code: ReportModuleCode.Advertising,
		children: [
			{
				title: 'Основные показатели',
				code: ReportModuleCode.AdvGeneral,
				children: [],
			},
			{
				title: 'Сравнение',
				code: ReportModuleCode.AdvCompare,
				children: [],
			},
		],
	},
	{
		title: 'Направления',
		code: ReportModuleCode.Directions,
		children: [
			{
				title: 'Загородка',
				code: ReportModuleCode.Zagorodka,
				children: [],
			},
			{
				title: 'Новостройки',
				code: ReportModuleCode.Novostroyki,
				children: [],
			},
			{
				title: 'Вторичка',
				code: ReportModuleCode.Vtorichka,
				children: [],
			},
			{
				title: 'Ипотека',
				code: ReportModuleCode.Ipoteka,
				children: [],
			},
			{
				title: 'Коммерческая',
				code: ReportModuleCode.Commercial,
				children: [],
			},
			{
				title: 'HR',
				code: ReportModuleCode.Hr,
				children: [],
			},
		],
	},
	{
		title: 'Агрегаторы',
		code: ReportModuleCode.Aggregators,
		children: [
			{
				title: 'Сводная',
				code: ReportModuleCode.AgSummary,
				children: [],
			},
			{
				title: 'Объекты',
				code: ReportModuleCode.AgObjects,
				children: [],
			},
			{
				title: 'Риелторы',
				code: ReportModuleCode.AgRieltors,
				children: [],
			},
		],
	},
	{
		title: 'Скоринг',
		code: ReportModuleCode.Scoring,
		children: [],
	},
	{
		title: 'SMM',
		code: ReportModuleCode.Smm,
		children: [],
	},
	{
		title: 'Клиенты',
		code: ReportModuleCode.Clients,
		children: [],
	},
	{
		title: 'Etagi.com',
		code: ReportModuleCode.Etagicom,
		children: [],
	},
	{
		title: 'Сохраненные фильтры',
		code: ReportModuleCode.SavedFilters,
		children: [],
	},
];
