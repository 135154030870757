import { ReportQueryParams } from './data/query-params.type';

export interface ReportSavedFilter {
	id: string;
	dashboard_id: string;
	name: string;
	params: Partial<ReportQueryParams>;
}

export interface ReportFilter {
	name: ReportFilterName;
	params: Partial<ReportQueryParams>;
	defaultParams?: Partial<ReportQueryParams>;
}

export const ReportFilterName = {
	Card: 'card',
	Chart: 'chart',
	Table: 'table',
	TableChart: 'table-chart',
} as const;

export type ReportFilterName =
	(typeof ReportFilterName)[keyof typeof ReportFilterName];
